.users_list
    position: relative
    .empty
        font-size: 18px
        text-align: center
        padding: 60px 0
        font-weight: 500
        color: #00000057
    .search
        display: flex
        input
            width: 100%
            padding: 10px
            border: 1px solid #64646442
        button
            padding: 10px
            width: 80px
            background-color: #FFC700
            border: 1px solid #64646442
            height: 100%
            color: #424242
            font-weight: 500
    .wrapper
        display: flex
        flex-direction: column
        .user
            width: 100%
            display: flex
            align-items: center
            justify-content: space-between
            border-bottom: 1px solid #64646442
            padding: 20px 0
            font-size: 15px
            &:last-child
                border-bottom: 0
            span
                font-weight: 700
.pagination 
  display: flex
  justify-content: center
  margin: 20px 0


.pagination a 
    border: 1px solid #ccc
    padding: 5px 10px
    margin: 0 5px
    text-decoration: none
    color: #333


.pagination a:hover 
    cursor: pointer    
    background-color: #ccc


.pagination .active 
    opacity: 0.5
    color: #fff


.pagination .break 
    padding: 5px 10px
