*, *:before, *:after
    box-sizing: border-box
    line-height: 150%
    font-family: 'Montserrat', sans-serif

html, body, div , span, object, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video
    margin: 0
    padding: 0
    border: 0
    font-size: 100%
    vertical-align: baseline
    text-rendering: optimizeLegibility
    -webkit-font-smoothing: antialiased
    text-size-adjust: none
    font-family: 'Manrope', sans-serif

footer, header, nav, section, main
    display: block

body
    line-height: 1
    background: #F5F5F5

blockquote, q
    quotes: none

blockquote:before, blockquote:after, q:before, q:after
    content: ''
    content: none

table
    border-collapse: collapse
    border-spacing: 0
    
*
    padding: 0
    margin: 0
    border: 0

*,*:before,*:after
    -moz-box-sizing: border-box
    -webkit-box-sizing: border-box
    box-sizing: border-box

:focus,:active
    outline: none
a:focus,a:active
    outline: none
nav,footer,header,aside
    display: block
html,body
    height: 100%
    width: 100%
    font-size: 100%
    line-height: 1
    font-size: 14px
    -ms-text-size-adjust: 100%
    -moz-text-size-adjust: 100%
    -webkit-text-size-adjust: 100%

input,button,textarea
    font-family: 'Montserrat', sans-serif
input::-ms-clear
    display: none

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

input[type="number"]
    -moz-appearance: textfield

input[type="search"]::-webkit-search-cancel-button
    -webkit-appearance: none

input[type="search"]::-webkit-search-decoration
    -webkit-appearance: none

button
    cursor: pointer
    background: transparent
button::-moz-focus-inner
    padding: 0
    border: 0
a, a:visited
    text-decoration: none
a:hover
    text-decoration: none
img
    vertical-align: top
h1,h2,h3,h4,h5,h6
    font-size: inherit
    font-weight: 400
ul li
    list-style: none


