.checkbox-wrapper
    display: flex
    align-items: center
    gap: 10px
    .switch
        display: inline-block
        height: 25px
        position: relative
        width: 44px
        input
            display: none
    .slider
        background-color: #ccc
        bottom: 0
        cursor: pointer
        left: 0
        position: absolute
        right: 0
        top: 0
        transition: .4s
        &.round
            border-radius: 34px
        &:before
            background-color: #fff
            bottom: 4px
            content: ""
            height: 17px
            left: 4px
            position: absolute
            transition: .4s
            width: 17px
            border-radius: 50%
    &.checked
        .slider
            background-color: #66bb6a
    &.checked
        .slider:before
            transform: translateX(19px)
