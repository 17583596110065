::-webkit-scrollbar
    width: 5px

::-webkit-scrollbar-track
    background: transparent
    padding: 0 2px

::-webkit-scrollbar-thumb
    background: rgba(145, 145, 145, 0.2)
    border-radius: 8px
    border-radius: 3px solid rgba(145, 145, 145, 0.2)

.page
    min-height: 100%
    &.not_found
        padding-bottom: 0
    @media (max-width: 1025px)
        margin: 0

@media screen and ( -webkit-min-device-pixel-ratio: 0 ) and ( max-width: 1000px )
    a, button
        -webkit-text-fill-color: #485268
    .footer_button_text, .white
        -webkit-text-fill-color: initial !important

body
    padding-top: 0px
// *:not(.traptrap)
//     animation-name: spin-and-color
//     animation-duration: 0.0001s
//     animation-timing-function: easy
//     animation-iteration-count: infinite
//     position: absolute

// @keyframes spin-and-color
//     0%
//         transform: rotate(0)
//         background-color: #23df20
//         right: -40px

//     25%
//         transform: rotate(90deg)
//         background-color: #09ccee

//     50%
//         transform: rotate(180deg)
//         background-color: #821ae3

//     75%
//         transform: rotate(270deg)
//         background-color: #e616d1

//     100%
//         transform: rotate(360deg)
//         left: -40px

// .traptrap
//     position: fixed
//     top: 50%
//     left: 50%
//     transform: translate(-50%, -50%)
//     z-index: 10000
//     font-size: 100px
//     background: #000
