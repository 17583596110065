.page_header
    background: #FFFFFF
    height: 70px
    display: flex
    justify-content: space-between
    box-shadow: inset 0px -1px 0px #DEE2ED
    font-weight: 700
    font-size: 24px
    line-height: 33px
    color: #485268
    width: 100%
    padding: 16px 40px
    align-items: center
    .btns
        display: flex
        align-items: center
        gap: 16px
        .submitEdit
            display: flex
            gap: 16px
            @media ( max-width: 600px )
                gap: 10px
        @media ( max-width: 600px )
            flex-direction: column-reverse
            align-items: flex-end
            button,a
                width: 100%

    button, a
        padding: 0 20px
        background-color: #ffffff
        display: flex
        align-items: center
        justify-content: center
        border-radius: 4px
        gap: 10px
        &.header-btn
            border: 1px solid #0000003f
            height: 38px
        &.btn
            background-color: #FFC700
        .icon
            width: 20px
            height: 20px
        span
            font-size: 12px
            font-weight: 600
        @media ( max-width: 600px )
            padding: 5px 20px
            height: auto
    @media ( max-width: 600px )
        height: 70px
        button, a
            padding: 5px 20px
    @media ( max-width: 500px )
        padding: 16px 25px
    @media ( max-width: 450px )
        padding: 10px 15px
