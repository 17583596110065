.modal
    background-color: #ffffff
    border-radius: 4px
    background-color: white
    width: 95%
    max-width: 600px
    border-radius: 4px
    display: flex
    flex-direction: column
    transition: 0.8s
    padding: 30px 20px
    gap: 16px
    font-size: 14px
    .close
        padding: 5px
    &__header
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        padding-bottom: 10px
        margin-bottom: 10px
        border-bottom: 1px solid rgba(0, 0, 0, 0.2)
        h3
            font-size: 20px
            font-weight: 500
    input
        width: 100%
    .input_wrapper
        font-size: 14px
        width: 100%
        p
            margin-bottom: 5px
        label
            width: 100%
        textarea
            width: 100%
            height: 100px
            max-width: 100%
            padding: 13px
            border: 1px solid #dedede
            border-radius: 4px
    .delete
        color: #990000
        font-weight: 500

.back
    position: fixed
    height: 100%
    width: 100%
    background-color: rgba(0, 0, 0, 0.5)
    left: 0
    top: 0
    right: 0
    bottom: 0
    z-index: 16
    display: flex
    align-items: center
    justify-content: center
    animation-duration: 0.0001s
    animation-timing-function: easy
    animation-iteration-count: infinite

.edit_back
    align-items: flex-start
    padding: 30px 0
    overflow-y: auto

.select
    min-width: 254px
    width: 100%
    height: 48px
    padding: 13px
    border-radius: 4px
    transition: 0.2s
    border: 1px solid #dedede
    &:focus
        border: 1px solid #bababa
    &::placeholder
        font-size: 16px

.btns
    display: flex
    justify-content: space-between
    gap: 16px

    .btn
        height: 40px
        color: black
    .deleteBtn
        background-color: #E35248
        color: #fff
    .cancelBtn
        background: transparent
