.service
    &-input
        background-color: white
        max-width: 600px
        width: 100%
        border-radius: 4px
        @media ( max-width: 800px)
            max-width: 100%
        input
            width: 100%
            border-radius: 4px
            padding: 8px 10px
            border: 1px solid #64646442
    &__header
        padding: 8px 40px
        height: 55px
        display: flex
        align-items: center
        justify-content: space-between
        background: #e9e9e9
        gap: 30px
        @media ( max-width: 800px)
            flex-direction: column
            height: auto
            gap: 20px
            padding: 15px 40px
            align-items: flex-end
        @media ( max-width: 500px)
            padding: 15px 25px
        @media ( max-width: 450px)
            padding: 15px 15px
        .select_wrapper
            display: flex
            align-items: center
            gap: 20px
            font-weight: 700
            select
                padding: 7px 40px 7px 12px
                width: 200px
                border: 1px solid #E8EAED
                border-radius: 5px
                background: white
                box-shadow: 0 1px 3px -2px #9098A9
                cursor: pointer
                font-family: inherit
                font-size: 16px
                transition: all 150ms ease
        button
            height: 38px
            padding: 0 20px
            background-color: #ffffff
            display: flex
            align-items: center
            justify-content: center
            border-radius: 4px
            gap: 10px
            border: 1px solid rgba(0, 0, 0, 0.25)
            white-space: nowrap
        .total
            display: flex
            align-items: center
            font-size: 14px
            gap: 3em
            span
                font-weight: 600
            @media ( max-width: 800px)
                justify-content: flex-end
                width: 100%
            @media ( max-width: 500px)
                flex-direction: column
                gap: 10px
                align-items: flex-end
                font-size: 15px
            p
                white-space: nowrap
    &__filters
        padding: 16px 40px
        display: grid
        grid-template-columns: repeat(5, 1fr)
        align-items: center
        justify-content: space-between
        background: #f7f7f7
        gap: 24px

.service-filter
    label
        display: flex
        gap: 10px
        flex-direction: column

.service-filter-select
    padding: 10px

.login-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 100vh
    background-color: #f1f1f1
    .login-form
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        width: 300px
        padding: 20px
        background-color: white
        box-shadow: 0px 0px 15px rgba(0,0,0,0.1)
        border-radius: 10px
        input
            width: 100%
            padding: 10px
            margin-bottom: 10px
            border: 1px solid #ccc
            border-radius: 5px
            box-sizing: border-box
        button
            width: 100%
            padding: 10px
            border: none
            border-radius: 5px
            background-color: #007aff
            color: white
            cursor: pointer
            &:hover
                background-color: #0051c7
