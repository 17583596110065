.service__sort
    display: grid
    background: white
    max-width: 95%
    margin: 30px auto 0
    padding: 20px 30px 15px
    border-radius: 16px 16px 0 0
    border-bottom: 1px solid #DEE2ED
    @media ( max-width: 600px)
        padding: 20px 15px 15px
    button
        display: flex
        align-items: center
        gap: 5px
        justify-content: center
        color: #aaa
        transition: 0.3s
        border-right: 1px solid #DEE2ED
        &:last-child
            border-right: 0
        svg
            path
                transition: 0.3s
                fill: #aaa
        &.active, &:hover
            color: #000
            svg
                path
                    fill: #000
