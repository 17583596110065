.statisticPage
    width: 100%
    height: 100%
    margin-top: 100px
    .bar
        height: 400px
        width: 600px
    .bars, .pies
        display: flex
        justify-content: center
        align-items: center
        gap: 50px
    .title 
        font-size: 20px
        font-weight: 700
        text-align: center
        padding-bottom: 20px
.arrow
    width: 30px
    margin-left: 50px