.input
    &__block 
        width: 100%
        .error
            margin-top: 5px   
    &__field
        min-width: 254px
        width: 100%
        height: 48px
        padding: 13px
        border-radius: 4px
        transition: 0.2s
        border: 1px solid #dedede

        &:focus 
            border: 1px solid #bababa
    
        &::placeholder 
            font-size: 16px
    &_error 
        background-color: #fff1ed
        border: 1px solid #ff7246 