.service__wrapper
    display: flex
    flex-direction: column
    gap: 10px
    background: white
    max-width: 95%
    margin: 0 auto
    padding: 10px 30px
    border-radius: 0 0 16px 16px
    @media ( max-width: 600px)
        padding: 10px 15px
    &>div
        border-bottom: 1px solid #DEE2ED
        padding: 10px 0
        display: grid
        align-items: center
        text-align: center
        gap: 10px
        &.card
            .card__status,
            .card__isactive
                button
                    max-width: 150px
                    width: 100%
                    margin: 0 auto
                    background: #aaa
                    border-radius: 4px
                    padding: 3px 0
                    display: flex
                    align-items: center
                    justify-content: center
                    gap: 7px
                    padding: 5px 10px
                    svg
                        height: 15px
                        width: 15px
                        path
                            fill: #000
                    &.active
                        background: #e9e9e9b4
            .card__name
                text-align-last: left
                @media ( max-width: 500px)
                    font-size: 15px
        &:last-child
            border-bottom: 0
        .edit
            display: flex
            align-items: center
            justify-content: flex-end
            width: 100%
.no-scroll
  overflow: hidden
  height: 100%
